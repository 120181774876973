<template>
  <div>
    <p v-if="title" class="averta-bold ml-4 mb-1">{{ title }}</p>
    <div v-if="showSearch">
      <b-input
        v-model="keyword"
        placeholder="Cari"
        class="input-search-2 input-none keyword-list-dropdown"
      ></b-input>
    </div>

    <div v-if="filteredDatas.length > 0">
      <div
        v-for="(item, index) in filteredDatas"
        :key="index"
        class="is-flex item-dropdown-client"
        @click="handleSelectData(item)"
      >
        <div class="flex-center">
          <div v-if="logoItem" class="logo-dropdown-client flex-center mr-2">
            <img v-if="item[logoItem]" :src="item[logoItem]" alt="" />
            <img v-else src="@/assets/img/empty-img-2.png" alt="" />
          </div>
          <p class="averta-reguler">{{ item[nameItem] }}</p>
        </div>
        <b-checkbox v-model="item.selected" size="is-small mr-0 ml-1">
        </b-checkbox>
      </div>
    </div>
    <div v-else>
      <p class="averta-reguler-italic color-grey ml-4">Data not found</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemListDropdown',
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    idItem: {
      type: String,
      default: ''
    },
    nameItem: {
      type: String,
      default: ''
    },
    logoItem: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      keyword: ''
    }
  },
  computed: {
    filteredDatas () {
      return this.datas.filter((item) =>
        item[this.nameItem].toLowerCase().includes(this.keyword.toLowerCase())
      )
    }
  },
  methods: {
    handleSelectData (item) {
      item.selected = !item.selected

      const resData = this.datas.map(data => data[this.idItem] === item[this.idItem] ? { ...data, selected: item.selected } : data)
      this.$emit('handleSelectData', { selected: item, datas: resData })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-dropdown-client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 8px;
  padding-right: 3px;
  margin: 0 6px;

  &:hover {
    background: #fff5f6;
  }
}

.logo-dropdown-client {
  width: 35px;
  height: 20px;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.keyword-list-dropdown {
  border: 1px solid #e1e1e1;
  margin: 0 15px;
  margin-bottom: 5px;
}
</style>
